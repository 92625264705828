import { render, staticRenderFns } from "./RecentlyVisited.vue?vue&type=template&id=265bcecd&scoped=true"
import script from "./RecentlyVisited.vue?vue&type=script&lang=js"
export * from "./RecentlyVisited.vue?vue&type=script&lang=js"
import style0 from "./RecentlyVisited.vue?vue&type=style&index=0&id=265bcecd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265bcecd",
  null
  
)

export default component.exports