var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.kadi.globals.user_active
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.items.length > 0,
              expression: "items.length > 0",
            },
          ],
          staticClass: "card",
        },
        [
          _c(
            "div",
            { staticClass: "card-header border-bottom-0 py-1" },
            [
              _c(
                "collapse-item",
                {
                  staticClass: "text-default stretched-link",
                  attrs: { id: _vm.id, "is-collapsed": _vm.collapsed },
                  on: {
                    collapse: function ($event) {
                      _vm.collapsed = $event
                    },
                  },
                },
                [_c("strong", [_vm._v(_vm._s(_vm.$t("Recently visited")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body items", attrs: { id: _vm.id } }, [
            _c(
              "div",
              { staticClass: "list-group list-group-flush" },
              _vm._l(_vm.items, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "list-group-item list-group-item-action",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "text-default stretched-link",
                        attrs: { href: item.endpoint },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "badge badge-light border font-weight-normal float-right ml-3",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.itemTypes[item.type] || item.type) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flow-root" }, [
                          _c(
                            "strong",
                            {
                              staticClass: "font-title elevated",
                              attrs: { title: item.title },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.kadi.utils.truncate(item.title, 50))
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "font-identifier" }, [
                          _vm._v("@" + _vm._s(item.identifier)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-muted font-timestamp mt-1" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("Last visited")) +
                                " "
                            ),
                            _c("from-now", {
                              attrs: { timestamp: item.timestamp },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }